import { defineStore } from "pinia";
import Api from "../services/Api";

export const commiteeStore = defineStore("commitee", {
  state: () => {
    return {
      commitee: null,
      commitees: [],
    };
  },
  getters: {
    commiteeNames: (state) => {
      let names = [];
      for (let com of state.commitees) {
        names.push(com.name);
      }
      //       names.push("None");
      return names;
    },
    singleCommitteeNames: (state) => {
      let names = [];
      let singles = state.commitees.filter(
        (committee) =>
          committee.type == "Select" || committee.type == "Sectoral"
      );
      for (let com of singles) {
        names.push(com.name);
      }
      return names;
    },
    jointCommitteeNames: (state) => {
      let names = [];
      let joints = state.commitees.filter(
        (committee) => committee.type == "joint"
      );
      for (let com of joints) {
        names.push(com.name);
      }
      return names;
    },
  },
  actions: {
    getCommitees() {
      return Api.getCommitees()
        .then((res) => {
          this.commitees = res.data.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    createCommitee(data) {
      return Api.createCommitee(data)
        .then((res) => {
          this.getCommitees();
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    addCommiteeMembers(data) {
      return Api.addCommiteeMembers(data)
        .then((res) => {
          this.getCommitees();
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    editCommitee(id, data) {
      return Api.editCommitee(id, data)
        .then((res) => {
          this.getCommitees();
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    deleteCommitee(id) {
      return Api.deleteCommitee(id)
        .then((res) => {
          this.getCommitees();
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
});
