<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">document uploads</div>
      </v-row>
      <v-row class="">
        <div class="">
          Upload all other documents like communication from the chair, clerk
          and also Hansards etc.
        </div>
      </v-row>
    </v-col>

    <v-row>
      <v-col cols="10">
        <v-list two-line class="text-capitalize">
          <v-divider></v-divider>
          <v-subheader class="text-uppercase black--text">payslips</v-subheader>
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Upload payslips </v-list-item-title>
              <v-list-item-subtitle>
                Upload payslips of each member.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn color="primary" small @click="payslipDialog = true">
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-subheader class="text-uppercase black--text"
            >other documents</v-subheader
          >
          <!-- Communication from the chair -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Communication from the chair -
              </v-list-item-title>
              <v-list-item-subtitle>
                This will appear at the dashboard. Only the latest one will be
                visible.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadComm('communication-chair')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- Communication from the clerk -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Communication from the clerk
              </v-list-item-title>
              <v-list-item-subtitle>
                This will appear at the dashboard. Only the latest one will be
                visible.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadComm('communication-clerk')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- Order papers -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Order Papers </v-list-item-title>
              <v-list-item-subtitle>
                Upload the order papers including the time, date and title
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('order-papers')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- Committee reports -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Committee reports </v-list-item-title>
              <v-list-item-subtitle>
                Upload the committee reports after each committee meeting.
                Requires the date, time and the name of the committee
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadWithCommitee('committee-reports')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- Public hearing -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Public hearing </v-list-item-title>
              <v-list-item-subtitle>
                Upload the public hearing document including the date, time,
                commitee and title.
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadWithCommitee('public-hearings')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- Hansard records -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Hansard records </v-list-item-title>
              <v-list-item-subtitle>
                Upload the hansard record for each meeting with it's date and
                time.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('hansard-records')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- Papers laid -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Papers Laid </v-list-item-title>
              <v-list-item-subtitle>
                Upload the Papers laid with it's date, time and title
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn color="primary" small @click="uploadNormal('papers-laid')">
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- Petitions -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Petitions </v-list-item-title>
              <v-list-item-subtitle>
                Upload each petition document with the date, time and title.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn color="primary" small @click="uploadNormal('petitions')">
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- Standing orders -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Standing order </v-list-item-title>
              <v-list-item-subtitle>
                Upload each standing order with the time, date and title.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('standing-orders')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- Statements -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Statements </v-list-item-title>
              <v-list-item-subtitle>
                Upload each statement with the time, date and title
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn color="primary" small @click="uploadNormal('statements')">
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- Evaluation reports -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Evaluation reports </v-list-item-title>
              <v-list-item-subtitle>
                Upload each report with the time, date and title
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('evaluation-reports')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
          <v-subheader class="text-uppercase black--text"
            >budget documents</v-subheader
          >
          <!-- key to budget document -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Key to budget document </v-list-item-title>
              <v-list-item-subtitle>
                Upload the key to budget document for this financial year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('key to budget')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- budget highlight -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> budget highlights </v-list-item-title>
              <v-list-item-subtitle>
                Upload the budget highlights for this financial year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('budget highlights')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- budget speech -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> budget speech </v-list-item-title>
              <v-list-item-subtitle>
                Upload the budget speech for this financial year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('budget speech')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- finance bill -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> finance bill </v-list-item-title>
              <v-list-item-subtitle>
                Upload the finance bill for this financial year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('finance bill')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- memorandum for financial bill -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                memorandum for financial bill
              </v-list-item-title>
              <v-list-item-subtitle>
                Upload the memorandum for the financial bill for this financial
                year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('memo for finance bill')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- implementation for the budget announcements -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                implementation for the budget announcements
              </v-list-item-title>
              <v-list-item-subtitle>
                Upload the implementations for the budget announcements for this
                financial year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('announcement implementation')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- budget at a glance -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> budget at a glance </v-list-item-title>
              <v-list-item-subtitle>
                Upload the budget at a glance document for this financial year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('budget glance')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- annual financial statement -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                annual financial statement
              </v-list-item-title>
              <v-list-item-subtitle>
                Upload the annual financial statement for this financial year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('annual financial statement')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- receipt budget -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> receipt budget </v-list-item-title>
              <v-list-item-subtitle>
                Upload the receipt budget for this financial year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('receipt budget')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- expenditure profile -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> expenditure profile </v-list-item-title>
              <v-list-item-subtitle>
                Upload the expenditure profile for this financial year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('expenditure profile')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- expenditure budget -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> expenditure budget </v-list-item-title>
              <v-list-item-subtitle>
                Upload the expenditure budget for this financial year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('expenditure budget')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- demand for grants -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> demand for grants </v-list-item-title>
              <v-list-item-subtitle>
                Upload the demands for grants for this financial year.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('grant demands')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <!-- custom notifications -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-circle-small </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> custom notifications </v-list-item-title>
              <v-list-item-subtitle>
                Upload the custom notificatiins here.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="primary"
                small
                @click="uploadNormal('custom notifications')"
              >
                <v-icon left>mdi-upload</v-icon> upload</v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <!-- Dialog to upload files -->
        <v-dialog v-model="dialog" :persistent="loading" width="auto">
          <v-card width="500">
            <v-card-title class="text-center text-uppercase">
              Upload {{ uploadData.type }}
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form ref="form" v-model="form">
                <!-- date -->
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Select Date"
                      prepend-icon="mdi-calendar"
                      :rules="[rules.required]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
                <!-- time -->
                <v-menu
                  ref="menu"
                  v-model="timeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time"
                      label="Select time"
                      prepend-icon="mdi-clock-time-four-outline"
                      :rules="[rules.required]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeMenu"
                    v-model="time"
                    format="24hr"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                  ></v-time-picker>
                </v-menu>
                <!-- title -->
                <v-text-field
                  v-model="title"
                  outlined
                  dense
                  v-if="uploadData.title"
                  :rules="[rules.required]"
                  label="Title of document"
                  prepend-icon="mdi-text"
                />
                <!-- commitee -->
                <v-autocomplete
                  v-model="committee"
                  outlined
                  dense
                  v-if="uploadData.committee"
                  prepend-icon="mdi-account-group"
                  label="Select Committee"
                  :items="commiteeNames"
                  :rules="[rules.required]"
                />
                <!-- document -->
                <v-file-input
                  v-model="file"
                  outlined
                  dense
                  label="Select the file"
                  :rules="[rules.required]"
                ></v-file-input>
                <!-- submit -->
                <v-btn
                  color="primary"
                  :disabled="!form"
                  block
                  :loading="loading"
                  @click="uploadFile()"
                  >Upload</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <!-- payslip dialog -->
    <v-dialog width="auto" v-model="payslipDialog">
      <v-card width="500">
        <v-card-title class="text-uppercase">
          Upload Payslip <v-spacer></v-spacer>
          <v-btn icon @click="payslipDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form v-model="payslipForm">
            <v-autocomplete
              outlined
              dense
              label="Recipient"
              prepend-icon="mdi-account"
              clearable
              :items="members"
              item-text="name"
              item-value="id"
              :loading="gettingMembers"
              :rules="[rules.required]"
              v-model="recipient"
            ></v-autocomplete>
            <v-file-input
              outlined
              dense
              label="Payslip"
              v-model="payslip"
              :rules="[rules.required]"
            ></v-file-input>
            <v-btn
              block
              color="primary"
              :disabled="payslipForm"
              :loading="uploadingPayslip"
              @click="uploadPayslip"
              >Upload</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- snackbar -->
    <v-snackbar
      v-model="snackBar"
      :color="snackBarData.success ? 'success' : 'error'"
    >
      <div class="text-center text-capitalize">
        {{ snackBarData.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import Api from "@/services/Api";
import { commiteeStore } from "../stores/commitees";
import { memberStore } from "../stores/members";
import { mapState, mapActions } from "pinia";

export default {
  name: "DocumentUpload",
  data: () => ({
    dialog: false,
    form: false,
    loading: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    time: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(11, 5),
    dateMenu: false,
    timeMenu: false,
    file: null,
    title: "",
    committee: "",
    committeeItems: [],
    rules: {
      required: (v) => !!v || "Required",
    },
    uploadData: {
      title: false,
      committee: false,
      type: "",
    },
    // payslip
    payslipDialog: false,
    payslip: null,
    recipient: "",
    gettingMembers: null,
    uploadingPayslip: false,
    payslipForm: false,
    // snakcbar
    snackBar: false,
    snackBarData: {
      success: false,
      message: "",
    },
  }),
  computed: {
    ...mapState(commiteeStore, ["commitees", "commiteeNames"]),
    ...mapState(memberStore, ["members"]),
  },
  watch: {
    payslipDialog(oldVal, newVal) {
      // only get members when payslip dialog is activated
      // only get members once i.e when oldval == null
      if (oldVal === null && newVal) {
        this.gettingMembers = true;
        this.getMembers().then(() => {
          this.gettingMembers = false;
        });
      }
    },
  },
  methods: {
    ...mapActions(commiteeStore, ["getCommitees"]),
    ...mapActions(memberStore, ["getMembers"]),
    uploadFile() {
      this.loading = true;
      // formulate payload
      let formData = new FormData();

      formData.append("type", this.uploadData.type);
      formData.append("file", this.file);
      formData.append("date", this.date);
      formData.append("time", this.time);

      // check if title is included
      if (this.title == "") {
        formData.append("name", this.uploadData.type);
      } else {
        formData.append("name", this.title);
      }
      // check if the commitee is required
      if (this.uploadData.committee) {
        let commitee_id;
        // find the commitee id
        for (let comm of this.commitees) {
          if (comm.name == this.committee) {
            commitee_id = comm.id;
          }
        }
        formData.append("commitee_id", commitee_id);
      }
      // upload with payload
      Api.uploadFile(formData)
        .then(() => {
          this.title = "";
          this.file = null;
          this.date = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          this.time = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(11, 5);
          this.showSnackBar(true, "Upload was succesfully");
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data);
        })
        .finally(() => {
          this.dialog = false;
          this.loading = false;
        });
    },
    uploadComm(type) {
      this.uploadData.committee = false;
      this.uploadData.title = false;
      this.uploadData.type = type;
      this.dialog = true;
    },
    uploadNormal(type) {
      this.uploadData.committee = false;
      this.uploadData.title = true;
      this.uploadData.type = type;
      this.dialog = true;
    },
    uploadWithCommitee(type) {
      this.uploadData.committee = true;
      this.uploadData.title = true;
      this.uploadData.type = type;
      this.dialog = true;
    },
    uploadPayslip() {
      this.uploadingPayslip = true;
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
  },
  created() {
    this.getCommitees();
  },
};
</script>

<style></style>
